import { convertDateNumToLocal } from "@fairview-group/utils";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Input, Row, Textarea } from "src/@fair";
import { useEndpoints } from "src/api";
import { AuthContext } from "src/auth";
import { AnnouncementPopUpBase, Break, BusinessDiscussionPopUpBase, PopUp, PopUpPropType } from "src/components";
import { ErrorLog } from "src/utils";

export const NotificationPopUps = () => {
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const endpoints = useEndpoints();

    const timerRef = useRef<NodeJS.Timeout>();
    const [saveState, setSaveState] = useState<boolean>(false);

    const [businessDiscussions, setBusinessDiscussions] = useState<Array<any>>([]);
    const [typeData, setTypeData] = useState<{ types: Array<any>, typeMap: any }>({ types: [], typeMap: {} });
    const [categoryData, setCategoryData] = useState<{ categories: Array<any>, categoryMap: any }>({ categories: [], categoryMap: {} });
    const [currentBD, setCurrentBD] = useState<number>(0);
    const [businessDiscussionPopUp, setBusinessDiscussionPopUp] = useState<boolean>(false);

    const [announcements, setAnnouncements] = useState<Array<any>>([]);
    const [currentAnnonuncement, setCurrentAnnouncement] = useState<number>(0);
    const [announcementPopUp, setAnnouncementPopUp] = useState<boolean>(false);


    const fetchData = async () => {
        try {
            const [bdRes, announcementRes] = await Promise.all([
                endpoints.businessDiscussions.fetchUnacknowledged(),
                endpoints.announcement.check()
            ])

            if (bdRes.data.error) throw new Error(bdRes.data.error ?? "Error fetching Business Discussions.");
            if (announcementRes.data.error) throw new Error(announcementRes.data.error ?? "Error fetching Announcements.");

            setBusinessDiscussions(bdRes.data.payload);
            setAnnouncements(announcementRes.data.payload);
        } catch (error) {
            ErrorLog(error);
        } finally {
        }
    }


    const onAnnouncementAcknowledge = async () => {
        try {
            setSaveState(true)

            const res = await endpoints.announcement.acknowledge({ id: announcements[0]?._id });

            if (!res.data.payload) throw new Error(res.data.error ?? "Error acknowledging Announcement.");

            setAnnouncementPopUp(false);
            timerRef.current = setTimeout(() => {
                setAnnouncements([...announcements.slice(1)]);
            }, 500)

        } catch (error) {
            ErrorLog(error);
        } finally {
            setSaveState(false);
        }
    }

    const onBusinessDiscussionAcknowledge = async () => {
        try {
            setSaveState(true)

            const res = await endpoints.businessDiscussions.acknowledge({ businessDiscussionId: businessDiscussions[0]?._id });

            if (!res.data.payload) throw new Error(res.data.error ?? "Error acknowledging Business Discussion.");

            setBusinessDiscussionPopUp(false);
            timerRef.current = setTimeout(() => {
                setBusinessDiscussions([...businessDiscussions.slice(1)]);
            }, 500)

        } catch (error) {
            ErrorLog(error);
        } finally {
            setSaveState(false);
        }
    }

    const checkForAnnoncementsOrBusinessDiscussions = () => {
        try {
            // console.log(authContext.announcements);
            if (announcements?.length > 0) {
                setAnnouncementPopUp(true);
                return;
            }
            if (businessDiscussions?.length > 0) {
                setBusinessDiscussionPopUp(true);
                return;
            }
        } catch (error) {
            ErrorLog(error);
        }
    }

    useEffect(() => {
        if (authContext.awaitable === true && authContext.token) {
            fetchData();
        }
    }, [location, authContext.awaitable, authContext.token]);

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        }
    }, [])

    useEffect(() => {
        checkForAnnoncementsOrBusinessDiscussions();
    }, [announcements, businessDiscussions])

    return (
        <Fragment>
            <AnnouncementPopUpBase
                announcementData={{
                    announcement: (announcements ?? [])[0],
                    index: currentAnnonuncement,
                    length: announcements.length
                }}
                onAcknowledge={onAnnouncementAcknowledge}
                onClose={() => {
                    setAnnouncementPopUp(false);
                }}
                disabled={saveState}
                popUpState={announcementPopUp}
            />
            <BusinessDiscussionPopUpBase
                businessDiscussionData={{
                    businessDiscussion: (businessDiscussions ?? [])[0],
                    index: currentBD,
                    length: businessDiscussions.length
                }}
                typeData={typeData}
                categoryData={categoryData}
                onAcknowledge={onBusinessDiscussionAcknowledge}
                onClose={() => {
                    setBusinessDiscussionPopUp(false);
                }}
                disabled={saveState}
                popUpState={businessDiscussionPopUp}
            />
        </Fragment>
    )
}