import "./index.scss";
import { CSSProperties, useContext, useEffect, useState, useRef, MouseEvent }       from "react";
import { ThemeContext, BaseComponentPropType, Button, Title, Text, ButtonPropType } from "src/@fair";
import { AiOutlineClose }                                                           from "react-icons/ai";
import { v4 as uuid }                                                               from "uuid";

interface PopUpOption extends ButtonPropType {
}

export interface PopUpPropType extends Omit<BaseComponentPropType<HTMLDivElement>, "title"> {
	/**
	 * Controlled state for pop up.
	 */
	popUpState: boolean,
	/**
	 * Title on the pop up card.
	 */
	title?: React.ReactNode | string,
	// /**
	//  * Pop up sub text.
	//  */
	// text?: string,
	/** */
	options?: {
		/**
		 * Justify options a specific direction (flex properties).
		 */
		justifyContent?: "space-between" | "space-evenly" | "start" | "end"
		/**
		 * Left option configuration.
		 */
		left?: PopUpOption,
		/**
		 * Right option configuration.
		 */
		right?: PopUpOption
	}
	/**
	 * Returns the state upon a pop up close (from clicking outside or the "X")
	 * @param state
	 * @returns
	 */
	onPopUpClose?: (state: boolean) => void
	/**
	 * Option that specifies if the popUp can be closed using the "X" button or by clicking outside the popUp. Defaults to false.
	 */
	mustConfirm?: boolean
	contentStyle?: CSSProperties
}


export const PopUp = (props: PopUpPropType) => {
	const [fade, setFade] = useState<boolean>(false);
	const [maskId, setMaskId] = useState<string>(uuid())
	const [popUpId, setPopUpId] = useState<string>(uuid())
	const themeContext = useContext(ThemeContext);
	const divProps = Object.assign({}, props);
	const popUpRef = useRef(null);
	delete divProps.popUpState;

	const handleFadeIn = () => {
		setFade(false);
		const mask = document.createElement('div');
		mask.setAttribute("id", maskId);
		mask.classList.add("pop-up-mask");
		document.body.appendChild(mask);
		setTimeout(() => {
			mask.classList.add("fade");
			document.getElementById(popUpId).classList.add("fade");
		}, 10)
	}

	const handleFadeOut = () => {
		const mask = document.getElementById(maskId)
		if (mask) {
			document.getElementById(popUpId).classList.remove("fade");
			mask.classList.remove("fade");
			setTimeout(() => {
				document.body.removeChild(mask)
				setFade(true);
			}, 250);
		}
		;
	}

	const handleOutsideClick = (event) => {
		if (props.mustConfirm !== true) {
			if (popUpRef.current) {
				if (!popUpRef.current.contains(event.target)) {
					props.onPopUpClose(false);
				}
			}
		}
	}

	useEffect(() => {
		if (props.popUpState === true) {
			handleFadeIn()
		} else {
			handleFadeOut()
		}
	}, [props.popUpState])

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		}
	}, []);

	useEffect(() => {
		return () => {
			const mask = document.getElementById(maskId)
			if (mask) document.body.removeChild(document.getElementById(maskId))
		}
	}, [])

	return (
		(props.popUpState || !fade) &&
        <div
            className={"pop-up-container"}
        >
            <div
                ref={popUpRef}
                id={popUpId}
                className={`pop-up ${themeContext.value}`}
                style={props.style}
            >
                <div 
					className={"pop-up-contents"}
					style={props.contentStyle}
				>
                    {props.mustConfirm !== true && <Button
						// className={"info-button"}
                        background={"transparent"}
                        className={"pop-up-close"}
                        style={{borderRadius: "2rem", padding: "10px"}}
						// notifications={{num: 1, backgroundColor: "#5F6BF3"}}
                        padding={0}
                        onClick={() => {
							if (props.onPopUpClose) {
								props.onPopUpClose(false);
							}
						}}
                    >
                        <AiOutlineClose
                            style={{
								position: "absolute",
								left: "50%",
								transform: "translate(-50%, -50%)",
								color: "grey"
							}}
                            size={16}
                        />
                    </Button>}
                    <div className={"pop-up-title"}>{props.title}</div>
                    <div className={"pop-up-text"}>{props.children}</div>
                    <div className={"pop-up-footer"}>
                        <div className={"pop-up-options"} style={{justifyContent: props.options?.justifyContent ?? "start"}}>

							{props.options?.left && <Button
								{...props.options.left}
								// variant={props.options.left?.variant ?? "filled"}
								// buttonType={props.options.left?.buttonType ?? "primary"}
								// size={props.options.left?.size ?? "sm"}
                            >
                            </Button>}
							{props.options?.right && <Button
								{...props.options.right}
								// variant={props.options.right?.variant ?? "filled"}
								// buttonType={props.options.right?.buttonType ?? "primary"}
								// size={props.options.right?.size ?? "sm"}
                            >
                            </Button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export * from "./bd-pop-up";
export * from "./announcement-pop-up";
export * from "./notif-pop-up";
export default PopUp;