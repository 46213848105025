import { Suspense, lazy, useContext, useEffect } from "react";
import { Routes as Router, Route, Navigate } from "react-router-dom";
import { Spinner } from "src/@fair";
import { Shell } from "./shell";
import { AuthContext, AuthContextType } from "./auth";
import { Permissions } from "@fairview-group/permissions";
import { Log } from "./utils";
import { AiOutlineComment, AiOutlineDashboard, AiOutlineNotification } from "react-icons/ai";
import { RiOrganizationChart } from "react-icons/ri";
import { TbUserShield } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdOutlineCategory, MdOutlineInventory2, MdOutlineLocationOn, MdOutlinePending } from "react-icons/md";
import { BsCalendarWeek, BsClipboard } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { FaDollarSign } from "react-icons/fa";

/***
 * Lazy-loaded pages
 * @type {React.LazyExoticComponent<() => JSX.Element>}
 */
const Login = lazy(async () => import("./pages/login"));
const Dashboard = lazy(async () => import("./pages/dashboard"));
const EditRoles = lazy(async () => import("./pages/edit-roles"));
const CreateRole = lazy(async () => import("./pages/create-role"));
const EditAccounts = lazy(async () => import("./pages/edit-accounts"));
const ViewAccounts = lazy(async () => import("./pages/view-accounts"));
const CreateAccount = lazy(async () => import("./pages/create-account"));
const ViewRoles = lazy(async () => import("./pages/view-roles"));
const NotFound = lazy(async () => import("./pages/404"));
const LinehaulGPS = lazy(async () => import("./pages/linehaul-gps"));
const Settings = lazy(async () => import("./pages/settings"));
const Sandbox = lazy(async () => import("./pages/sandbox"));
const PDTrucks = lazy(async () => import("./pages/view-pd-trucks"));
const CreatePDTruck = lazy(async () => import("./pages/create-truck"));
const CreateWorkOrder = lazy(async () => import("./pages/create-work-order"));
const ViewWorkOrders = lazy(async () => import("./pages/view-work-orders"));
const ViewWorkOrdersLegacy = lazy(async () => import("./pages/view-work-orders-legacy"));
const AnalysisRunSheet = lazy(async () => import("./pages/analysis-run-sheet"));
const ViewRunSheets = lazy(async () => import("./pages/view-run-sheets"));
const EditRunSheet = lazy(async () => import("./pages/edit-run-sheet"));
const EditRunSheetManager = lazy(async () => import("./pages/edit-run-sheet-manager"));
const BugReport = lazy(async () => import("./pages/bug-report"));
const ViewBugReports = lazy(async () => import("./pages/view-bug-reports"));
const ViewAuditLog = lazy(async () => import("./pages/view-audit-log"));
const UploadSchedule = lazy(async () => import("./pages/upload-schedule"));
const ViewSchedules = lazy(async () => import("./pages/view-schedules"));
const CreateSupplier = lazy(async () => import("./pages/create-supplier"));
const ViewSuppliers = lazy(async () => import("./pages/view-suppliers"));
const ViewExpenses = lazy(async () => import("./pages/view-expenses"));
const UploadReceipt = lazy(async () => import("./pages/upload-receipt"));
const WeeklyPayroll = lazy(async () => import("./pages/weekly-payroll"));
const Verify = lazy(async () => import("./pages/verify"));
const ForgotPasswordPage = lazy(async () => import("./pages/forgot-password"));
const CreateBusinessDiscussion = lazy(async () => import("./pages/create-business-discussion"));
const ViewBusinessDiscussions = lazy(async () => import("./pages/view-business-discussions"));
const ViewBusinessDiscussionsSelf = lazy(async () => import("./pages/view-business-discussions-self"));
const EditBusinessDiscussion = lazy(async () => import("./pages/edit-business-discussion"));
const CreateInventoryType = lazy(async () => import("./pages/create-inventory-type"));
const EditInventoryType = lazy(async () => import("./pages/edit-inventory-type"));
const ViewInventoryTypes = lazy(async () => import("./pages/view-inventory-types"));
const AddInventoryItem = lazy(async () => import("./pages/create-inventory-item"));
const ViewInventory = lazy(async () => import("./pages/view-inventory"));
const ViewMMR = lazy(async () => import("./pages/view-mmr"));
const ViewTerminals = lazy(async () => import("./pages/view-terminals"));
const CreateTerminal = lazy(async () => import("./pages/create-terminal"));
const EditTerminal = lazy(async () => import("./pages/edit-terminal"));
const ViewAssignments = lazy(async () => import("./pages/view-assignments"));
const CreateAssignment = lazy(async () => import("./pages/create-assignment"));
const EditAssignment = lazy(async () => import("./pages/edit-assignment"));
const SignUp = lazy(async () => import("./pages/sign-up"));
const AcceptInvite = lazy(async () => import("./pages/accept-invite"));
const ResetPassword = lazy(async () => import("./pages/reset-password"));
const ViewPending = lazy(async () => import("./pages/view-pending"));
const ViewBusinessDiscussionTypes = lazy(async () => import("./pages/view-business-discussion-types"));
const CreateBusinessDiscussionType = lazy(async () => import("./pages/create-business-discussion-type"));
const EditBusinessDiscussionType = lazy(async () => import("./pages/edit-business-discussion-type"));
const ViewBusinessDiscussionCategories = lazy(async () => import("./pages/view-business-discussion-categories"));
const CreateBusinessDiscussionCategory = lazy(async () => import("./pages/create-business-discussion-category"));
const EditBusinessDiscussionCategory = lazy(async () => import("./pages/edit-business-discussion-category"));
const ManageOrganization = lazy(async () => import("./pages/manage-organization"));
const Impersonate = lazy(async () => import("./pages/impersonate"));
const ViewAnnouncements = lazy(async () => import("./pages/view-announcements"));
const CreateAnnouncement = lazy(async () => import("./pages/create-announcement"));
const ViewLinehaulLocations = lazy(async () => import("./pages/view-linehaul-locations"));
const CreateLinehaulLocation = lazy(async () => import("./pages/create-linehaul-location"));
const EditLinehaulLocation = lazy(async () => import("./pages/edit-linehaul-location"));
const ViewLinehaulTrucks = lazy(async () => import("./pages/view-linehaul-trucks"));
const CreateLinehaulTruck = lazy(async () => import("./pages/create-linehaul-truck"));
const ViewLinehaulRunSheetsSelf = lazy(async () => import("./pages/view-linehaul-run-sheets-self"));
const LinehaulRunSheetsRecap = lazy(async () => import("./pages/view-linehaul-run-sheets-all"));
const EditLinehaulRunSheet = lazy(async () => import("./pages/edit-linehaul-run-sheet"));
const EditLinehaulTruckConfig = lazy(async () => import("./pages/edit-linehaul-truck-config"));
const ViewLinehaulAssignments = lazy(async () => import("./pages/view-linehaul-assignments"));
const CreateLinehaulAssignment = lazy(async () => import("./pages/create-linehaul-assignment"));
const EditLinehaulAssignment = lazy(async () => import("./pages/edit-linehaul-assignment"));
const EditLinehaulRunSheetOther = lazy(async () => import("./pages/edit-linehaul-run-sheet-other"));
const CreateOrg = lazy(async () => import("./pages/create-org"));
const ViewOrganizations = lazy(async () => import("./pages/view-organizations"));
const CreateOrganizationAuth = lazy(async () => import("./pages/create-organization-auth"));
// const EditOrganization = lazy(async () => import("./pages/edit-organization"));
const EditPDTruckConfig = lazy(async () => import("./pages/edit-pd-truck-config"));
const Landing = lazy(async () => import("./pages/landing"));
const CreateReport = lazy(async () => import("./pages/reports/create-report"))
const Support = lazy(async () => import("./pages/misc/support")) 
const FeatureRequest = lazy(async () => import("./pages/misc/feature-request"));
const ViewScheduleGroups = lazy(async () => import("./pages/schedule-group/view"));
const CreateScheduleGroup = lazy(async () => import("./pages/schedule-group/create"));
const EditScheduleGroup = lazy(async () => import("./pages/schedule-group/edit"));
const ViewDSW = lazy(async () => import("./pages/dsw/view"));
const ViewDSWJobs = lazy(async () => import("./pages/dsw/jobs"));

export interface RouteType {
    pageName: string,
    url: string,
    /**
     * Used to designate routes that require url params to function properly (ex: edit pages require "id" param).
     * Some pages have id params optionally (view pages, create work order), but it can function without them.
     */
    needsUrlParams?: boolean,
    component: JSX.Element,
    /**
     * Render condition (usually a role permission)
     */
    condition?: boolean
    icon?: React.ReactNode
    /**
     * Name for bread crumb
     */
    breadcrumb?: string,
}
// can't using {[key: string]: RouteDefintion} because it loses static keys
// dont know how this works but it maintains types as RouteDefintion of keys without losing static keys (login, dashboard, etc.)
const asRouteDict = <K extends PropertyKey>(
    o: { [P in K]: RouteType }) => o;

const useUnAuthenticatedRoutes = () => {
    const routes = {
        login: {
            pageName: "Login",
            url: "/login",
            component: <Login />
        },
        verify: {
            pageName: "Verify",
            url: "/verify",
            component: <Verify />
        },
        forgot_password: {
            pageName: "Forgot Password",
            url: "/forgot-password",
            component: <ForgotPasswordPage />,
        },
        reset_password: {
            pageName: "Reset Password",
            url: "/reset-password",
            component: <ResetPassword />
        },
        sign_up: {
            pageName: "Sign Up",
            url: "/sign-up",
            component: <SignUp />
        },
        accept_invite: {
            pageName: "Accept Invite",
            url: "/accept-invite",
            component: <AcceptInvite />
        },
        create_org: {
            pageName: "Create Organization",
            url: "/sign-up/create-organization",
            component: <CreateOrg />
        }
        // impersonate: {
        //     pageName: "Impersonate",
        //     url: "/impersonate",
        //     component: <Impersonate/>
        // }
    }
    return asRouteDict(routes);
}

const useAuthenticatedRoutes = () => {
    const authContext = useContext<AuthContextType>(AuthContext);

    return authContext.awaitable && authContext.role
        ? asRouteDict({
            dashboard: {
                pageName: "Dashboard",
                url: "/dashboard",
                component: <Dashboard />,
                icon: <AiOutlineDashboard />,
                condition: true,
            },
            // administration: {
            //     pageName: "Administration",
            //     url: "",
            //     component: <Dashboard/>,
            //     // condition: authContext.role.role.authority === 1
            //     // condition: authContext.role.administrator.has(Permissions.administrator.manage_organization.permission)
            //     condition: authContext.role.administrator.has(Permissions.administrator.manage_organization.permission) || authContext.role.account.has(Permissions.account.view.permission)
            // },
            manage_organization: {
                pageName: "Manage Organization",
                url: "/manage-organization",
                component: <ManageOrganization org={true} />,
                // condition: authContext.role.role.authority === 1
                condition: authContext.role.administrator.has(Permissions.administrator.manage_organization.permission),
                icon: <RiOrganizationChart />
            },
            roles: {
                pageName: "Roles",
                url: "/roles",
                component: <ViewRoles />,
                condition: authContext.role.role.has(Permissions.role.view.permission),
                icon: <TbUserShield />
            },
            roles_create: {
                pageName: "Create Role",
                url: "/roles/create",
                component: <CreateRole />,
                condition: authContext.role.role.has(Permissions.role.create.permission)
            },
            roles_edit: {
                pageName: "Edit Role",
                url: "/roles/edit",
                needsUrlParams: true,
                component: <EditRoles />,
                condition: authContext.role.role.has(Permissions.role.edit.permission)
            },
            users: {
                pageName: "Users",
                url: "/users",
                component: <ViewAccounts />,
                condition: authContext.role.account.has(Permissions.account.view.permission),
                icon: <HiOutlineUserGroup />
            },
            users_create: {
                pageName: "Add User",
                url: "/users/add",
                component: <CreateAccount />,
                condition: authContext.role.account.has(Permissions.account.create.permission)
            },
            users_edit: {
                pageName: "Edit User",
                url: "/users/edit",
                needsUrlParams: true,
                component: <EditAccounts />,
                condition: (authContext.role.account.has(Permissions.account.edit.permission) || authContext.role.account.has(Permissions.account.view.permission))
            },
            pending_users: {
                pageName: "Pending Users",
                url: "/pending-users",
                component: <ViewPending />,
                condition: authContext.role.account.has(Permissions.account.view.permission),
                icon: <MdOutlinePending />
            },
            pending_users_edit: {
                pageName: "Edit Pending User",
                url: "/pending-users/edit",
                needsUrlParams: true,
                component: <EditAccounts />,
                condition: authContext.role.account.has(Permissions.account.edit.permission)
            },
            create_business_discussion: {
                pageName: "Create Business Discussion",
                url: "/business-discussions/create",
                component: <CreateBusinessDiscussion />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.create.permission)
            },
            my_discussion_discussions_edit: {
                pageName: "Edit My Business Discussion",
                url: "/my-business-discussions/edit",
                needsUrlParams: true,
                component: <EditBusinessDiscussion />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.create.permission)
            },
            business_discussions: {
                pageName: "Business Discussions",
                url: "/business-discussions",
                component: <ViewBusinessDiscussions />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.view_all.permission) || authContext.role.business_discussion.has(Permissions.business_discussion.view.permission),
                icon: <AiOutlineComment />,
            },
            my_business_discussions: {
                pageName: "My Business Discussions",
                url: "/my-business-discussions",
                component: <ViewBusinessDiscussionsSelf />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.view.permission)
            },
            business_discussions_types: {
                pageName: "Types",
                url: "/business-discussions/types",
                component: <ViewBusinessDiscussionTypes />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.type_view.permission),
                icon: <BsClipboard />
            },
            business_discussions_types_edit: {
                pageName: "Edit Business Discussion Type",
                url: "/business-discussions/types/edit",
                needsUrlParams: true,
                component: <EditBusinessDiscussionType />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.type_edit.permission)
            },
            business_discussions_create_type: {
                pageName: "Create Business Discussion Type",
                url: "/business-discussions/types/create",
                component: <CreateBusinessDiscussionType />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.type_create.permission)
            },
            business_discussions_categories: {
                pageName: "Categories",
                url: "/business-discussions/categories",
                component: <ViewBusinessDiscussionCategories />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.category_view.permission),
                icon: <MdOutlineCategory />
            },
            business_discussions_categories_edit: {
                pageName: "Edit Business Discussion Category",
                url: "/business-discussions/categories/edit",
                needsUrlParams: true,
                component: <EditBusinessDiscussionCategory />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.category_edit.permission)
            },
            business_discussions_create_category: {
                pageName: "Create Business Discussion Category",
                url: "/business-discussions/categories/create",
                component: <CreateBusinessDiscussionCategory />,
                condition: authContext.role.business_discussion.has(Permissions.business_discussion.category_create.permission)
            },
            pd_trucks: {
                pageName: "P&D Truck List",
                url: "/pd-trucks",
                breadcrumb: "P&D Trucks",
                component: <PDTrucks />,
                condition: authContext.role.pd.has(Permissions.pd.truck_view.permission),
            },
            pd_trucks_mmr: {
                pageName: "MMR Reports Edit",
                url: "/pd-trucks/mmr",
                breadcrumb: "MMR",
                needsUrlParams: true,
                component: <ViewMMR />,
                condition: authContext.role.pd.has(Permissions.pd.truck_view.permission) || authContext.role.linehaul.has(Permissions.linehaul.mmr.permission)
            },
            pd_trucks_create: {
                pageName: "Create PD Truck",
                url: "/pd-trucks/create",
                component: <CreatePDTruck />,
                condition: authContext.role.pd.has(Permissions.pd.truck_create.permission)
            },
            pd_trucks_config_edit: {
                pageName: "P&D Trucks Settings",
                url: "/pd-trucks/settings",
                component: <EditPDTruckConfig />,
                condition: (authContext.role.pd.has(Permissions.pd.truck_settings_view.permission)),
            },
            run_sheets_recap: {
                pageName: "Run Sheet Recap",
                url: "/pd-run-sheets/recap",
                component: <AnalysisRunSheet />,
                condition: authContext.role.pd.has(Permissions.pd.run_sheets_recap.permission)
            },
            pd_daily_service_worksheets: {
                pageName: "Daily Service Worksheets",
                url: "/daily-service-worksheets",
                component: <ViewDSW />,
                condition: authContext.role.pd.has(Permissions.pd.dsw_view.permission) && authContext.organization?.features?.dsw
            },
            pd_daily_service_worksheets_jobs: {
                pageName: "DSW Jobs",
                url: "/daily-service-worksheets/runners",
                component: <ViewDSWJobs />,
                condition: authContext.role.pd.has(Permissions.pd.dsw_runner_view.permission) && authContext.organization?.features?.dsw
            },
            run_sheets_list: {
                pageName: "Run Sheet List",
                url: "/pd-run-sheets",
                component: <ViewRunSheets />,
                condition: authContext.role.pd.has(Permissions.pd.run_sheets_list.permission),
                breadcrumb: "P&D Run Sheets",
            },
            run_sheets_list_submit: {
                pageName: "Submit Run Sheet",
                url: "/pd-run-sheets/submit",
                needsUrlParams: true,
                component: <EditRunSheet />,
                condition: authContext.role.pd.has(Permissions.pd.run_sheets_submit.permission)
            },
            run_sheets_recap_edit: {
                pageName: "Run Sheet Edit",
                url: "/pd-run-sheets/recap/edit",
                needsUrlParams: true,
                component: <EditRunSheetManager />,
                condition: authContext.role.pd.has(Permissions.pd.run_sheets_edit.permission)
            },
            settings: {
                pageName: "Settings",
                url: "/settings",
                component: <Settings />,
                condition: true,
            },
            submit_bug_report: {
                pageName: "Submit Bug Report",
                url: "/bug-report",
                component: <BugReport />,
                condition: true,
            },
            create_work_order: {
                pageName: "Create Work Order",
                url: "/work-orders/create",
                component: <CreateWorkOrder />,
                condition: authContext.role.work_order.has(Permissions.work_order.create.permission)
            },
            work_orders: {
                pageName: "Work Orders",
                url: "/work-orders",
                component: <ViewWorkOrders />,
                condition: authContext.role.work_order.has(Permissions.work_order.view_all.permission) || authContext.role.work_order.has(Permissions.work_order.view.permission)
            },
            work_orders_legacy: {
                pageName: "Legacy Work Orders",
                url: "/work-orders-legacy",
                component: <ViewWorkOrdersLegacy />,
                condition: authContext.role.work_order.has(Permissions.work_order.view_all.permission) && authContext.organization?.features?.work_order_legacy
            },
            suppliers: {
                pageName: "Suppliers",
                url: "/suppliers",
                component: <ViewSuppliers />,
                condition: authContext.role.work_order.has(Permissions.work_order.supplier_view.permission)
            },
            suppliers_create: {
                pageName: "Create Supplier",
                url: "/suppliers/create",
                component: <CreateSupplier />,
                condition: authContext.role.work_order.has(Permissions.work_order.supplier_create.permission)
            },
            create_inventory_type: {
                pageName: "Create Inventory Type",
                url: "/inventory/types/create",
                component: <CreateInventoryType />,
                condition: authContext.role.inventory.has(Permissions.inventory.type_create.permission)
            },
            types: {
                pageName: "Inventory Types",
                url: "/inventory/types",
                component: <ViewInventoryTypes />,
                condition: authContext.role.inventory.has(Permissions.inventory.type_view.permission),
                icon: <BiCategory />
            },
            types_edit: {
                pageName: "Edit Inventory Type",
                url: "/inventory/types/edit",
                needsUrlParams: true,
                component: <EditInventoryType />,
                condition: authContext.role.inventory.has(Permissions.inventory.type_edit.permission)
            },
            add_item: {
                pageName: "Add Inventory Item",
                url: "/inventory/create",
                component: <AddInventoryItem />,
                condition: authContext.role.inventory.has(Permissions.inventory.item_create.permission),
            },
            inventory: {
                pageName: "Inventory",
                url: "/inventory",
                component: <ViewInventory />,
                condition: authContext.role.inventory.has(Permissions.inventory.item_view.permission),
                icon: <MdOutlineInventory2 />
            },
            upload_receipt: {
                pageName: "Upload Receipt",
                url: "/expenses/create",
                component: <UploadReceipt />,
                condition: authContext.role.financial.has(Permissions.financial.receipt_upload.permission)
            },
            view_expenses: {
                pageName: "Expenses",
                url: "/expenses",
                component: <ViewExpenses />,
                condition: authContext.role.financial.has(Permissions.financial.receipt_view_all.permission) || authContext.role.financial.has(Permissions.financial.receipt_view.permission),
                icon: <FaDollarSign />
            },
            weekly_receipt: {
                pageName: "Weekly Payroll",
                url: "/weekly-payroll",
                component: <WeeklyPayroll />,
                condition: authContext.role.financial.has(Permissions.financial.weekly_payroll.permission),
                icon: <BsCalendarWeek />
            },
            upload_schedule: {
                pageName: "Upload Schedule",
                url: "/schedules/upload",
                component: <UploadSchedule />,
                condition: authContext.role.schedule.has(Permissions.schedule.create.permission)
            },
            view_schedules: {
                pageName: "View Schedules",
                url: "/schedules",
                component: <ViewSchedules />,
                condition: (authContext.role.schedule.has(Permissions.schedule.view.permission) || authContext.role.schedule.has(Permissions.schedule.view_all.permission)),

            },
            create_schedule_group: {
                pageName: "Create Schedule Group",
                url: "/schedules/groups/create",
                component: <CreateScheduleGroup />,
                condition: authContext.role.schedule.has(Permissions.schedule.group_create.permission)
            },
            edit_schedule_group: {
                pageName: "Edit Schedule Group",
                url: "/schedules/groups/edit",
                component: <EditScheduleGroup />,
                condition: authContext.role.schedule.has(Permissions.schedule.group_edit.permission),

            },
            view_schedule_groups: {
                pageName: "View Schedule Groups",
                url: "/schedules/groups",
                component: <ViewScheduleGroups />,
                condition: authContext.role.schedule.has(Permissions.schedule.group_view.permission),

            },
            sandbox: {
                pageName: "Sandbox",
                url: "/sandbox",
                component: <Sandbox />,
                condition: authContext.role.administrator.has(Permissions.administrator.all.permission)
            },
            terminals: {
                pageName: "Terminals",
                url: "/terminals",
                component: <ViewTerminals />,
                condition: authContext.role.terminal.has(Permissions.terminal.view.permission),
                icon: <MdOutlineLocationOn />
            },
            create_terminal: {
                pageName: "Create Terminal",
                url: "/terminals/create",
                component: <CreateTerminal />,
                condition: authContext.role.terminal.has(Permissions.terminal.create.permission)
            },
            terminals_edit: {
                pageName: "Edit Terminal",
                url: "/terminals/edit",
                needsUrlParams: true,
                component: <EditTerminal />,
                condition: authContext.role.terminal.has(Permissions.terminal.edit.permission)
            },
            assignments: {
                pageName: "Assignments",
                url: "/pd-run-sheets/assignments",
                component: <ViewAssignments />,
                condition: authContext.role.pd.has(Permissions.pd.assignment_view.permission),
            },
            create_assignment: {
                pageName: "Create Assignment",
                url: "/pd-run-sheets/assignments/create",
                component: <CreateAssignment />,
                condition: authContext.role.pd.has(Permissions.pd.assignment_create.permission)
            },
            assignments_edit: {
                pageName: "Edit Assignment",
                url: "/pd-run-sheets/assignments/edit",
                needsUrlParams: true,
                component: <EditAssignment />,
                condition: authContext.role.pd.has(Permissions.pd.assignment_edit.permission)
            },
            announcements: {
                pageName: "Announcements",
                url: "/announcements",
                component: <ViewAnnouncements />,
                condition: (authContext.role.announcement.has(Permissions.announcement.view.permission) || authContext.role.announcement.has(Permissions.announcement.view_all.permission)),
                icon: <AiOutlineNotification />
            },
            announcements_create: {
                pageName: "Create Announcement",
                url: "/announcements/create",
                component: <CreateAnnouncement />,
                condition: authContext.role.announcement.has(Permissions.announcement.create.permission),
            },
            linehaul_locations: {
                pageName: "Linehaul Locations",
                url: "/linehaul-locations",
                component: <ViewLinehaulLocations />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.location_view.permission)),
            },
            linehaul_locations_create: {
                pageName: "Create Linehaul Location",
                url: "/linehaul-locations/create",
                component: <CreateLinehaulLocation />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.location_create.permission)),
            },
            linehaul_locations_edit: {
                pageName: "Edit Linehaul Location",
                url: "/linehaul-locations/edit",
                component: <EditLinehaulLocation />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.location_edit.permission)),
                needsUrlParams: true
            },
            linehaul_trucks: {
                pageName: "Linehaul Trucks",
                url: "/linehaul-trucks",
                component: <ViewLinehaulTrucks />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.truck_view.permission)),
            },
            linehaul_trucks_create: {
                pageName: "Create Linehaul Truck",
                url: "/linehaul-trucks/create",
                component: <CreateLinehaulTruck />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.truck_create.permission)),
            },
            linehaul_truck_config_edit: {
                pageName: "Linehaul Trucks Settings",
                url: "/linehaul-trucks/settings",
                component: <EditLinehaulTruckConfig />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.truck_settings_view.permission)),
            },
            linehaul_trucks_mmr: {
                pageName: "MMR Reports Edit",
                url: "/linehaul-trucks/mmr",
                needsUrlParams: true,
                component: <ViewMMR />,
                condition: authContext.role.linehaul.has(Permissions.linehaul.mmr.permission)
            },
            linehaul_run_sheets_self: {
                pageName: "Linehaul Run Sheet List",
                url: "/linehaul-run-sheets",
                component: <ViewLinehaulRunSheetsSelf />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.run_sheets_list.permission)),
            },
            linehaul_run_sheets_recap: {
                pageName: "Linehaul Run Sheet Recap",
                url: "/linehaul-run-sheets/recap",
                component: <LinehaulRunSheetsRecap />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.run_sheets_recap.permission)),
            },
            linehaul_run_sheets_recap_edit: {
                pageName: "Run Sheet Edit",
                url: "/linehaul-run-sheets/recap/edit",
                needsUrlParams: true,
                component: <EditLinehaulRunSheetOther />,
                condition: authContext.role.linehaul.has(Permissions.linehaul.run_sheets_edit.permission)
            },
            linehaul_run_sheets_submit: {
                pageName: "Linehaul Run Sheet Submit",
                url: "/linehaul-run-sheets/submit",
                component: <EditLinehaulRunSheet />,
                condition: (authContext.role.linehaul.has(Permissions.linehaul.run_sheets_submit.permission)),
                needsUrlParams: true,
            },
            linehaul_assignments: {
                pageName: "Assignments",
                url: "/linehaul-run-sheets/assignments",
                component: <ViewLinehaulAssignments />,
                condition: authContext.role.linehaul.has(Permissions.linehaul.assignment_view.permission)
            },
            linehaul_create_assignment: {
                pageName: "Create Assignment",
                url: "/linehaul-run-sheets/assignments/create",
                component: <CreateLinehaulAssignment />,
                condition: authContext.role.linehaul.has(Permissions.pd.assignment_create.permission)
            },
            linehaul_assignments_edit: {
                pageName: "Edit Assignment",
                url: "/linehaul-run-sheets/assignments/edit",
                needsUrlParams: true,
                component: <EditLinehaulAssignment />,
                condition: authContext.role.linehaul.has(Permissions.linehaul.assignment_edit.permission)
            },
            view_organizations: {
                pageName: "Organizations",
                url: "/organizations",
                component: <ViewOrganizations />,
                condition: true,
            },
            create_organization: {
                pageName: "Organizations",
                url: "/organizations/create",
                component: <CreateOrganizationAuth />,
                condition: true,
            },
            edit_organization: {
                pageName: "Organizations",
                url: "/organizations/edit",
                component: <ManageOrganization org={false} />,
                condition: true,
                needsUrlParams: true,
            },
            report: {
                pageName: "Reports",
                url: "/reports",
                component: <CreateReport/>,
                condition: authContext.role.report.has(Permissions.report.create.permission)
            },
            support: {
                pageName: "Support",
                url: "/support",
                component: <Support/>,
                condition: true
            },
            feature_request: {
                pageName: "Feature Request",
                url: "/feature-request",
                component: <FeatureRequest/>,
                condition: true ,
            }
        }) : null
}

export const useRoutes = () => {
    // const authContext = useContext<AuthContextType>(AuthContext)
    const unauthenticated = useUnAuthenticatedRoutes();
    const authenticated = useAuthenticatedRoutes();
    // establish unchanging ids (without needing to store in db) that map to url for each route
    // allows for easy change of 
    return {
        unauthenticated,
        authenticated
    }
}

export const getRouteFromUrl = (): (url?: string) => { route: RouteType, routeKey: string, } => {
    const routes = useRoutes();

    // this is done to prevent excessive hook calls, and enforce same hook order (there are times when this is used inside dynamic loops)
    return (
        (url?: string) => {
            const keys = Object.keys(routes.authenticated);
            let route: RouteType;
            let routeKey: string;
            for (let i = 0; i < keys.length; i++) {
                routeKey = keys[i];
                route = routes.authenticated[routeKey];
                if (route.url === (url ?? window.location.pathname)) break;
            }
            return {
                route,
                routeKey
            }
        }
    )
}

export const Routes = () => {
    const authContext = useContext<AuthContextType>(AuthContext);
    const routes = useRoutes();

    useEffect(() => {
        Log(authContext)
    }, [authContext])
    return (
        <Router>

            <Route path={"/verify"} element={<Suspense fallback={<Spinner />}><Verify /></Suspense>} />
            <Route path={"/accept-invite"} element={<Suspense fallback={<Spinner />}><AcceptInvite /></Suspense>} />
            <Route path={"impersonate"} element={<Impersonate />} />
            {
                // suspense necessary incase lazy load does not complete by the time someone clicks it
                <Route path={"/sign-up"} element={<Suspense fallback={<Spinner />}><SignUp /></Suspense>} />
            }
            {
                // suspense necessary incase lazy load does not complete by the time someone clicks it
                <Route path={"/sign-up/create-organization"} element={<Suspense fallback={<Spinner />}><CreateOrg /></Suspense>} />
            }
            {
                // suspense necessary incase lazy load does not complete by the time someone clicks it
                <Route path={"/set-up"} element={<Suspense fallback={<Spinner />}><SignUp /></Suspense>} />
            }

            {

                authContext.awaitable === undefined
                    // Waiting Authentication
                    ? <Route path={"*"} element={<Spinner />} />
                    : authContext.awaitable === false

                        /***
                         * Non-Authenticated Routes
                         */
                        ? (<Route key={"shell"} element={<Shell />}>
                            {
                                process.env["REACT_APP_ENVIRONMENT"] === "Production" 
                                ? <Route key={"root"} path={"/"} element={<Landing />} />
                                : <Route key={"root"} path={"/"} element={<Login />} />
                            }
                            {
                                Object.keys(routes.unauthenticated ?? {}).map((key) => {
                                    const route: RouteType = routes.unauthenticated[key];
                                    return <Route key={key} path={route.url} element={route.component} />
                                })
                            }

                            <Route key={"wildcard"} path={"*"} element={(<Navigate to={"/login"} />)} />
                        </Route>)

                        /***
                         * Authenticated Routes
                         */
                        : (<Route element={<Shell />}>

                            <Route path={"/login"} element={<Navigate to={"/dashboard"} />} />

                            {
                                authContext.contentAwaitable === undefined
                                    ? <Route key={"root"} path={"/"} element={<Spinner />} />
                                    : authContext.contentAwaitable === false
                                        ? null
                                        : <Route key={"root"} path={"/"} element={<Navigate to={"/dashboard"} />} />
                            }
                            {
                                Object.keys(routes.authenticated ?? {}).map((key) => {
                                    const route: RouteType = routes.authenticated[key];
                                    // console.log(route);
                                    return (
                                        authContext.contentAwaitable === undefined
                                            ? <Route key={key} path={route.url} element={<Spinner />} />
                                            : authContext.contentAwaitable === false
                                                ? null
                                                : (route.condition !== undefined ? route.condition : true)
                                                    ? (<Route key={key} path={route.url} element={route.component} />)
                                                    : null
                                    )
                                })
                            }

                            <Route path={"*"} element={(<NotFound />)} />
                        </Route>)

            }
        </Router>
    );
};