import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
import { useDSWJobsEndpoints } from "./jobs";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/pd" + "/dsw"

const useDSWEndpoints = () => {
    const jobs = useDSWJobsEndpoints()
    const view = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/view"); 

    return {
        jobs,
        view
    }
}

export {
    baseURL,
    useDSWEndpoints,
};