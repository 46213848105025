import "./index.scss";
import { classCombine, convertDateNumToLocal } from "@fairview-group/utils";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Text, ThemeContext } from "src/@fair";
import { useEndpoints } from "src/api";
import { AuthContext } from "src/auth";
import { Break, PopUp, PopUpPropType } from "src/components";
import { ErrorLog } from "src/utils";

interface AnnouncementPopUpProps extends Omit<PopUpPropType, "options"> {
    announcementData: {
        announcement: any,
        index: number,
        length: number,
    },
    onAcknowledge: () => void,
    onClose: () => void
    disabled: boolean,
}

export const AnnouncementPopUpBase = (props: AnnouncementPopUpProps) => {
    const themeContext = useContext(ThemeContext);

    return (
        <PopUp
            popUpState={props.popUpState}
            onPopUpClose={props.onPopUpClose}
            title={
                <Fragment
                    key={0}
                >
                    <div>
                        <div
                            style={{
                                fontSize: "1.1rem"
                            }}
                        >
                            {`
                            Announcement${props.announcementData.length > 1 ? ` (${props.announcementData.index + 1} of ${props.announcementData.length})` : ""}
                            ` }
                        </div>
                        <div
                            style={{
                                fontWeight: "400"
                            }}
                        >
                            {
                                (props.announcementData.announcement?.subject ?? "")
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between"
                        }}
                    >
                        <div>
                            {`${props.announcementData?.announcement?.created?.by?.name}`}
                        </div>
                        <div>
                            {`${convertDateNumToLocal(props.announcementData.announcement?.created?.date).split("T")[0]}`}
                        </div>
                    </div>
                    <Break style={{margin: "0", paddingBottom: "1rem"}}/>
                </Fragment>
            }
            // style={{padding: "0rem"}}
            options={ {
                justifyContent: "end",
                right: {
                    size: "sm",
                    buttonType: "primary",
                    children: "Acknowledge",
                    onClick: props.onAcknowledge,
                    loadState: props.disabled
                },
                // right: {
                //     size: "sm",
                //     buttonType: "primary",
                //     children: "Close",
                //     onClick: props.onClose,
                //     variant: "outlined"
                // }
            } }
            mustConfirm={true}
        >
            <pre
                className={classCombine("announcement-text", themeContext.value)}
            >	

                {props.announcementData.announcement?.body}
            </pre>
            <Break style={{margin: "0", paddingBottom: "1rem"}}/>
        </PopUp>
    )
}

export const AnnouncementPopUp = () => {
    const authContext = useContext(AuthContext);
    const [announcements, setAnnouncements] = useState<Array<any>>([]);
    const location = useLocation();
    const endpoints = useEndpoints();
    const [currentAnnonuncement, setCurrentAnnouncement] = useState<number>(0);
    const [saveState, setSaveState] = useState<boolean>(false);
    const timerRef = useRef<NodeJS.Timeout>();
    const [popUp, setPopUp] = useState<boolean>(false);

    const fetchData = async () => {
        try {
            const res = await endpoints.announcement.check();

            if (!res.data.payload) throw new Error(res.data.error ?? "Error fetching Announcements.");

            setAnnouncements(res.data.payload);
        } catch (error) {
            ErrorLog(error);
        } finally {
        }
    }

    
	const onAnnouncementAcknowledge = async () => {
		try {
			setSaveState(true)

			const res = await endpoints.announcement.acknowledge({ id: announcements[0]?._id }); 

			if (!res.data.payload) throw new Error(res.data.error ?? "Error acknowledging Announcement.");

			setPopUp(false);
			timerRef.current = setTimeout(() => {
				setAnnouncements([...announcements.slice(1)]);
			}, 500)

		} catch (error) {
			ErrorLog(error);
		} finally {
			setSaveState(false);
		}
	}
    

    useEffect(() => {
        if (authContext.awaitable === true) {
            fetchData();
        }
    }, [location, authContext.awaitable]);

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        }
    }, [])

    useEffect(() => {
        if (announcements.length > 0) {
            setPopUp(true);
        }
    }, [announcements])

    return (
        <AnnouncementPopUpBase
            announcementData={{
                announcement: (announcements ?? [])[0],
                index: currentAnnonuncement,
                length: announcements.length
            }}
            onAcknowledge={onAnnouncementAcknowledge}
            onClose={() => {
                setPopUp(false);
            }}
            disabled={saveState}
            popUpState={popUp}
        />
    )
}

export default AnnouncementPopUpBase;