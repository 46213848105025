import { convertDateNumToLocal } from "@fairview-group/utils";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Input, Row, Textarea } from "src/@fair";
import { useEndpoints } from "src/api";
import { AuthContext } from "src/auth";
import { Break, PopUp, PopUpPropType } from "src/components";
import { ErrorLog } from "src/utils";

interface BusinessDiscussionPopUpProps extends Omit<PopUpPropType, "options"> {
    businessDiscussionData: {
        businessDiscussion: any,
        index: number,
        length: number,
    },
    typeData: {
        types: Array<any>,
        typeMap: any
    },
    categoryData: {
        categories: Array<any>,
        categoryMap: any
    }
    onAcknowledge: () => void,
    onClose: () => void
    disabled: boolean,
}

export const BusinessDiscussionPopUpBase = ({popUpState, onPopUpClose, businessDiscussionData, onAcknowledge, disabled, typeData, categoryData}: BusinessDiscussionPopUpProps) => {
    return (

        <PopUp
            popUpState={popUpState}
            onPopUpClose={onPopUpClose}
            title={
                <Fragment
                    key={0}
                >
                    <div>
                        <div
                            style={{
                                fontSize: "1.1rem"
                            }}
                        >
                            {`
                            Business Discussion${businessDiscussionData.length > 1 ? ` (${businessDiscussionData.index + 1} of ${businessDiscussionData.length})` : ""}
                            ` }
                        </div>
                        <div
                            style={{
                                fontWeight: "400"
                            }}
                        >
                            {
                                (businessDiscussionData.businessDiscussion?.subject ?? "")
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between"
                        }}
                    >
                        <div>
                            {`${convertDateNumToLocal(businessDiscussionData.businessDiscussion?.created?.date).split("T")[0]}`}
                        </div>
                    </div>
                    <Break style={{margin: "0", paddingBottom: "1rem"}}/>
                </Fragment>
            }
            style={{ padding: "0rem" }}
            options={{
                justifyContent: "end",
                right: {
                    size: "sm",
                    buttonType: "primary",
                    children: "Acknowledge",
                    onClick: onAcknowledge,
                    loadState: disabled
                },
            }}
            mustConfirm={true}
        >
            {/* <Container width={"200px"} style={}> */}
            <Row padding={0} style={{ maxWidth: "500px" }}>

                <Col extends={{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6
                }}
                    padding={0}
                >
                    <Input
                        className={"bd-field"}
                        label={"Type"}
                        placeholder={"Type"}
                        width={"100%"}
                        value={typeData?.typeMap?.[businessDiscussionData?.businessDiscussion?.type]?.name ?? businessDiscussionData?.businessDiscussion?.type ?? ""}
                        disabled={true}
                    />
                </Col>

                <Col extends={{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6
                }}
                    padding={0}
                >
                    <Input
                        className={"bd-field"}
                        label={"Category"}
                        placeholder={"Category"}
                        value={categoryData?.categoryMap?.[businessDiscussionData?.businessDiscussion?.category]?.name ?? businessDiscussionData?.businessDiscussion?.category ?? ""}
                        disabled={true}
                        width={"100%"}
                    />
                </Col>

                <Col extends={{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6
                }}
                    padding={0}
                >
                    <Input
                        className={"bd-field"}
                        label={"Employee"}
                        placeholder={"Employee"}
                        width={"100%"}
                        value={businessDiscussionData?.businessDiscussion?.employee?.name ?? ""}
                        disabled={true}
                    />
                </Col>

                <Col extends={{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6
                }}
                    padding={0}
                >
                    <Input
                        className={"bd-field"}
                        label={"Created By"}
                        placeholder={"Created By"}
                        value={businessDiscussionData?.businessDiscussion?.created?.by ?? ""}
                        disabled={true}
                        width={"100%"}
                    />
                </Col>

                <Col
                    extends={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12
                    }} padding={0}>
                </Col>

                <Col extends={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12
                }}
                    padding={0}
                >
                    <Textarea
                        className={"bd-field-text-area"}
                        label={"Details"}
                        placeholder={"Details"}
                        value={businessDiscussionData?.businessDiscussion?.details ?? ""}
                        width={"100%"}
                        disabled={true}
                    />
                </Col>
            </Row>
            {/* </Container> */}
        </PopUp>
    )
}


export const BusinessDiscussionPopUp = () => {
    const authContext = useContext(AuthContext);
    const [businessDiscussions, setBusinessDiscussions] = useState<Array<any>>([]);
    const [typeData, setTypeData] = useState<{ types: Array<any>, typeMap: any }>({ types: [], typeMap: {} });
    const [categoryData, setCategoryData] = useState<{ categories: Array<any>, categoryMap: any }>({ categories: [], categoryMap: {} });
    const location = useLocation();
    const endpoints = useEndpoints();
    const [currentBD, setCurrentBD] = useState<number>(0);
    const [saveState, setSaveState] = useState<boolean>(false);
    const timerRef = useRef<NodeJS.Timeout>();
    const [popUp, setPopUp] = useState<boolean>(false);

    const fetchData = async () => {
        try {
            const res = await endpoints.businessDiscussions.fetchUnacknowledged();

            if (!res.data.payload) throw new Error(res.data.error ?? "Error fetching Business Discussions.");

            setBusinessDiscussions(res.data.payload);
        } catch (error) {
            ErrorLog(error);
        } finally {
        }
    }

    
	const onBusinessDiscussionAcknowledge = async () => {
		try {
			setSaveState(true)

			const res = await endpoints.businessDiscussions.acknowledge({ businessDiscussionId: businessDiscussions[0]?._id }); 

			if (!res.data.payload) throw new Error(res.data.error ?? "Error acknowledging Business Discussion.");

			setPopUp(false);
			timerRef.current = setTimeout(() => {
				setBusinessDiscussions([...businessDiscussions.slice(1)]);
			}, 500)

		} catch (error) {
			ErrorLog(error);
		} finally {
			setSaveState(false);
		}
	}
    

    useEffect(() => {
        if (authContext.awaitable === true) {
            fetchData();
        }
    }, [location, authContext.awaitable]);

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        }
    }, [])

    useEffect(() => {
        if (businessDiscussions.length > 0) {
            setPopUp(true);
        }
    }, [businessDiscussions])

    return (
        <BusinessDiscussionPopUpBase
            businessDiscussionData={{
                businessDiscussion: (businessDiscussions ?? [])[0],
                index: currentBD,
                length: businessDiscussions.length
            }}
            typeData={typeData}
            categoryData={categoryData}
            onAcknowledge={onBusinessDiscussionAcknowledge}
            onClose={() => {
                setPopUp(false);
            }}
            disabled={saveState}
            popUpState={popUp}
        />
    )
}