import { Container, useBreakpoint, } from "src/@fair";
import { Suspense, useContext, } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "./auth";
import { classCombine } from "@fairview-group/utils";
import SideNav from "./components/navbar-new";
import HeaderNav from "./components/nav-header";
import { Footer, Header, NotificationPopUps } from "./components";

export const getWidth = () => {
	const breakPoint = useBreakpoint();

	return () => {
		if (breakPoint === "md" || breakPoint === "lg" || breakPoint === "xl") {
			return true;
		} else {
			return false;
		}
	}
}

/***
 * Shell Component - a "shell" around our main application
 *  ...
 * @param {any} children
 * @returns {JSX.Element}
 * @constructor
 */
export const Shell = ({ children }: { children?}): JSX.Element => {
	const authContext = useContext(AuthContext);
	/***
	 * The entry to our application
	 * @returns {any}
	 * @constructor
	 */
	const Proxy = () => (children) ?? (<Outlet />);
	return (
		<>
			<div id={"shell"}>

				{
					/***
					 * Auth-backed components
					 */
					authContext.awaitable === true
						? <HeaderNav />
						: null
				}

				<div
					id={"content-nav-container"}
				>

					{
						/***
						 * Auth-backed components
						 */
						authContext.awaitable === true
							? <SideNav />
							: null
					}
					<Container
						id={"content"}
						className={
							classCombine(
								`${authContext.awaitable === false
									? "auth"
									: authContext.awaitable === true
										? ""
										: ""
								}`,
								// themeContext.value
							)

						}
						padding={0}
					>
						{
							/***
							 * Auth-backed components
							 */
							authContext.awaitable === true
								// && checkWidth
								? <Header />
								: null
						}
						<Suspense fallback={null}>
							<div
								id={"content-2"}
								className={"content-2"}
							>
								<Proxy />
							</div>
						</Suspense>
					</Container>
					{authContext?.impersonationInfo && <Footer />}
				</div>
				<NotificationPopUps/>
			</div>
		</>
	);
};

export default Shell;